@if (activeMethodology || statusBasedViewMode == 'create') {
  @if (data?.code) {
    <div class="flex flex-wrap gap-3 justify-content-between">
      <h4 class="mb-2">
        {{ moduleString + '.riskMatrix.label' | translate: 'Risk Matrix' }}
      </h4>
      @if (
        statusBasedViewMode != 'view' &&
        !formGroup?.controls?.likelihoodValuesDto?.value?.length &&
        !formGroup?.controls?.impactValuesDto?.value?.length &&
        !formGroup?.controls?.thresholdValuesDto?.value?.length
      ) {
        <app-button [action]="copyFromMethodologyAction"></app-button>
      }
    </div>
    <!-- <p-divider class="w-full" align="left">
      <b>{{ moduleFieldString + '.RiskMatrix.label' | translate: 'Risk Matrix' }}</b>
    </p-divider> -->
    <div class="w-full mt-4">
      <app-risk-methodology-summary
        [methodology]="activeMethodology"
        [maxThreshold]="maxThreshold"
      ></app-risk-methodology-summary>
    </div>
    @if (activeMethodology || statusBasedViewMode == 'create') {
      <div class="w-full">
        <div class="mt-5 tabs methodology-tabs">
          <p-tabView>
            <p-tabPanel
              header="{{ moduleFieldString + '.likelihoodValues.label' | translate: 'Likelihood Values' }}"
              leftIcon="pi pi-bell"
            >
              <ng-template pTemplate="content">
                <div class="col-12 md:col-12">
                  <app-add-reorder-table
                    [control]="formGroup?.controls?.likelihoodValuesDto"
                    [colsFilter]="['code', 'name', 'value', 'occuresFrom', 'occuresTo', 'occurrenceDuration']"
                    [formOptions]="{ methodology: data?.code }"
                    [extraFilters]="[{ property: 'methodology', operation: 'EQUAL', value: data?.code }]"
                    [customSort]="['displayOrder,asc']"
                    [moduleKeyword]="'RISK_METHODOLOGY_LIKELIHOOD_VALUE'"
                    [viewMode]="statusBasedViewMode"
                  ></app-add-reorder-table>
                </div>
              </ng-template>
            </p-tabPanel>
            <p-tabPanel
              header="{{ moduleFieldString + '.impactValues.label' | translate: 'Impact Values' }}"
              leftIcon="pi pi-bell"
            >
              <ng-template pTemplate="content">
                <div class="col-12 md:col-12">
                  <app-add-reorder-table
                    [control]="formGroup?.controls?.impactValuesDto"
                    [colsFilter]="['code', 'name', 'value']"
                    [formOptions]="{ methodology: data?.code, values: impactValuesList }"
                    [extraFilters]="[{ property: 'methodology', operation: 'EQUAL', value: data?.code }]"
                    [customSort]="['displayOrder,asc']"
                    [moduleKeyword]="'RISK_METHODOLOGY_IMPACT_VALUE'"
                    [viewMode]="statusBasedViewMode"
                  ></app-add-reorder-table>
                </div>
              </ng-template>
            </p-tabPanel>

            <p-tabPanel
              header="{{ moduleFieldString + '.thresholdInfo.label' | translate: 'Threshold Info' }}"
              leftIcon="pi pi-bell"
            >
              <div class="">
                <div class="col-12 md:col-12">
                  <app-add-reorder-table
                    [control]="formGroup?.controls?.thresholdValuesDto"
                    [formOptions]="{
                      methodology: data?.code,
                      min: minThreshold,
                      max: maxThreshold,
                      thresholdList: tempThresholdList?.length ? tempThresholdList : thresholdList,
                    }"
                    [onlyLastisntViewMode]="true"
                    [disableAdd]="checkThresholdsAdd"
                    [colsFilter]="['code', 'name', 'min', 'max', 'color']"
                    [reorderable]="false"
                    [extraFilters]="[{ property: 'methodology', operation: 'EQUAL', value: data?.code }]"
                    [customSort]="['displayOrder,asc']"
                    [moduleKeyword]="'RISK_METHODOLOGY_THRESHOLD_VALUE'"
                    [viewMode]="statusBasedViewMode"
                  ></app-add-reorder-table>
                </div>
                <!-- @if (statusBasedViewMode != 'view') {
                  <div class="w-full text-right">
                    <app-button [action]="addItemButtonActionPopup"></app-button>
                  </div>
                } -->
                <p-divider align="left">
                  <b>{{ moduleFieldString + '.AcceptableRisk.label' | translate: 'Acceptable Risk' }}</b>
                </p-divider>
                <div class="formgrid grid p-fluid mb-2">
                  <div class="col-11">
                    <app-p-slider-input
                      [name]="'value'"
                      [control]="acceptableRiskControl"
                      [type]="'number'"
                      [viewMode]="fieldViewMode"
                      [min]="options?.floor"
                      [max]="options?.ceil"
                      [range]="false"
                      [disabled]="statusBasedViewMode == 'view'"
                    ></app-p-slider-input>
                  </div>
                  <div class="col-1">
                    <app-button [action]="submitSaveButtonAction"></app-button>
                  </div>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
      </div>
    }
  }
}
@if (!activeMethodology && fieldViewMode == 'edit') {
  <div class="custom-skeleton">
    <div class="flex mb-3">
      <div class="w-full mb-2">
        <p-skeleton styleClass="mb-2"></p-skeleton>
        <p-skeleton width="50%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="20%" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="80%" height="2rem" styleClass="mb-2"></p-skeleton>
        <p-skeleton width="65%" height="4rem"></p-skeleton>
      </div>
    </div>
    <p-skeleton width="100%" height="150px"></p-skeleton>
    <div class="flex justify-content-end gap-2 mt-3">
      <p-skeleton width="8rem" height="2rem"></p-skeleton>
      <p-skeleton width="8rem" height="2rem"></p-skeleton>
    </div>
  </div>
}
