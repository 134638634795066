import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ButtonColor, ButtonStyle, IAction, IViewMode, RiskMethodologyThresholdValue } from '@shared/classes';
import { RiskMethodologyThresholdValueDataService } from 'app/modules/risk/services/data/risk-methodology-threshold-value-data.service';
import { min } from 'lodash-es';
import { Subject, debounceTime, switchMap } from 'rxjs';

@Component({
  selector: 'app-threshold-item',
  templateUrl: './threshold-item.component.html',
  styleUrls: ['./threshold-item.component.scss'],
})
export class ThresholdItemComponent implements OnInit, OnChanges {
  @Input() threshold: RiskMethodologyThresholdValue;
  @Input() floor: any;
  @Input() ceil: any;
  @Input() last: boolean = false;
  @Input() disabled: boolean;
  @Input() viewMode: IViewMode;
  private itemCodeChange$ = new Subject<void>(); // Subject to handle debouncing

  // @Output('onChange') onChange: EventEmitter<any> = new EventEmitter();
  @Output('onRemoveThresholdItem') onRemoveThresholdItem: EventEmitter<any> = new EventEmitter();
  tableActions: IAction[] = [
    {
      id: 2,
      icon: 'pi pi-trash',
      buttonStyle: ButtonStyle.Text,
      buttonClass: 'w-full',
      command: this.onRemove.bind(this),
      color: ButtonColor.Danger,
      passEvent: true,
    },
  ];

  formGroup: FormGroup;

  value: number = 50;
  options = {
    floor: 0,
    ceil: 100,
    disabled: false,
  };
  constructor(public thresholdValueService: RiskMethodologyThresholdValueDataService) {
    this.formGroup = new FormGroup({
      value: new FormControl(null),
      name: new FormControl(),
      color: new FormControl(),
    });
    // this.formGroup.valueChanges.subscribe(x => {
    //   this.onChange.emit();
    // })
  }

  ngOnChanges(changes: SimpleChanges) {
    //Couldn't use the supplied onChange events, they dont refresh the rest of the items,even with manual refresh
    // todo: check how to properly remove the ngOnChanges for better optimization
    this.setNewOptions(min([this.floor, this.ceil]), this.ceil, this.disabled);

    if (this.threshold?.max < this.options?.floor) {
      this.threshold.max = this.options?.floor;
    }

    if (!this.disabled && this.threshold?.max > this.options?.floor) {
      let oldValue = this.threshold?.max;

      this.threshold.max = this.threshold.max - 1;
      setTimeout(() => {
        this.threshold.max = this.threshold?.max > oldValue ? this.threshold?.max : oldValue;
      }, 1);
    }

    if (!this.disabled && this.threshold?.max > this.options?.ceil) {
      this.threshold.max = this.options?.ceil;
    }
    if (this.disabled) {
      let oldValue = this.threshold?.max;

      this.threshold.max = this.threshold.max + 1;
      setTimeout(() => {
        this.threshold.max = this.options?.ceil;
      }, 1);
    }
  }

  ngOnInit(): void {
    this.itemCodeChange$
      .pipe(
        debounceTime(500),
        switchMap(() =>
          this.thresholdValueService.patchSingleUpdate(
            this.getDataKeyValueFormatFromPrev(this.threshold, { color: null, value: null, name: null }),
            this.threshold.code
          )
        )
      )
      .subscribe({
        next: (response) => {
          // console.log('Request successful:', response);
        },
        error: (err) => {
          // console.error('Request failed:', err);
        }
      });
    this.options.floor = min([this.floor, this.ceil]);
    this.options.ceil = this.ceil;
    this.options.disabled = this.disabled;
    this.thresholdValueService.create({ createItems: this.getDataKeyValueFormat({ ...this.threshold }) }).subscribe(res => {
      if (res) {
        this.threshold.code = res.data?.code;
        this.formGroup.patchValue({
          ...this.threshold,
        });
        // setTimeout(() => {
        this.threshold.max = this.threshold.max;
        // }, 1000);
      }
    });
  }
  onRemove() {
    this.onRemoveThresholdItem.emit(this.threshold);
  }

  onChange() {
    if (this.threshold?.code) {
      this.itemCodeChange$.next(); // Emit event to the Subject
    }
  }

  getDataKeyValueFormatFromPrev(fields, prevValues = null) {
    let resFields: { key: string; value: any }[] = [];
    const prevkeys = prevValues ? Object.keys(prevValues) : [];
    Object.keys(fields).forEach((key) => {
      if (!prevValues || (prevValues && fields[key] != prevValues[key]) && prevkeys.includes(key))
        resFields.push({ key: key, value: fields[key] !== undefined ? fields[key] : null });
    });

    return resFields;
  }

  getDataKeyValueFormat(fields, prevValues = null) {
    let resFields: { key: string; value: any }[] = [];
    Object.keys(fields).forEach((key) => {
      if (!prevValues || (prevValues && fields[key] != prevValues[key]))
        resFields.push({ key: key, value: fields[key] !== undefined ? fields[key] : null });
    });

    return resFields;
  }


  setNewOptions(newFloor: number, newCeil: number, newDisabled: boolean): void {
    // Due to change detection rules in Angular, we need to re-create the options object to apply the change
    const newOptions = Object.assign({}, this.options);
    newOptions.floor = newFloor;
    newOptions.ceil = newCeil;
    newOptions.disabled = newDisabled;
    this.options = newOptions;
  }


  get moduleFieldString() {
    return "modules.risk.riskMethodology.fields";
  }
}
