import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseForm,
  FilterItem,
  IAction,
  IDynamicComponent,
  IViewMode,
  ModuleKeywords,
  RiskMethodology,
  RiskMethodologyDto,
  RiskMethodologyImpactValue,
  RiskMethodologyThresholdValue
} from '@shared/classes';
import { BaseFormPopupComponent } from '@shared/components/misc/base-form-popup/base-form-popup.component';
import { AppDialogService, IConfirmationConfig, IConfirmationPosition } from '@shared/services/app-dialog.service';
import { ViewModeService } from '@shared/services/view-mode.service';
import { RiskMethodologyDataService } from 'app/modules/risk/services/data/risk-methodology-data.service';
import { RiskMethodologyImpactFactorDataService } from 'app/modules/risk/services/data/risk-methodology-impact-factor-data.service';
import { RiskMethodologyImpactValueDataService } from 'app/modules/risk/services/data/risk-methodology-impact-value-data.service';
import { RiskMethodologyLikelihoodValueDataService } from 'app/modules/risk/services/data/risk-methodology-likelihood-value-data.service';
import { RiskMethodologyThresholdValueDataService } from 'app/modules/risk/services/data/risk-methodology-threshold-value-data.service';
import { findIndex, maxBy, minBy } from 'lodash-es';
import { ImpactFactorOptionsFormPopupComponent } from '../impact-factor-options-form-popup/impact-factor-options-form-popup.component';
import { RiskMethodologyCopyFormComponent } from '../risk-methodology-copy-form/risk-methodology-copy-form.component';

@Component({
  selector: 'app-risk-methodology-matrix',
  templateUrl: './risk-methodology-matrix.component.html',
  styleUrl: './risk-methodology-matrix.component.scss'
})
export class RiskMethodologyMatrixComponent extends BaseForm<RiskMethodologyDto> implements OnInit, AfterViewInit {
  impactFactorOptionsFormPopupComponent = ImpactFactorOptionsFormPopupComponent;
  acceptableRisk: number = 0;
  @Input() acceptableRiskControl = new FormControl(0);
  emitPaused = false;
  thresholdValid = true;
  options = {
    floor: 0,
    ceil: 100,
  };

  @Input() submitButtonActionPopup: IAction = {
    id: 1,
    label: 'Save',
    buttonType: 'button',
    command: this.onSubmitConfirm.bind(this),
    icon: 'pi pi-save',
    passEvent: true,
  };

  addItemButtonActionPopup: IAction = {
    id: 1,
    label: 'Add Item',
    buttonType: 'button',
    command: this.onAddThreshold.bind(this),
    buttonClass: '',
    icon: 'pi pi-plus',
    passEvent: true,
  };

  copyFromMethodologyAction: IAction = {
    id: 1,
    label: 'Copy From Another Methodology',
    buttonType: 'button',
    command: this.onCopyFromAnotherMethodology.bind(this),
    buttonClass: '',
    icon: 'pi pi-file',
    passEvent: true,
  };
  extraImpactFactorFilters: FilterItem[] = [];
  extraThresholdFilters: FilterItem[] = [];

  activeMethodology: RiskMethodology;
  timestamp;
  likelihoodValuesFields: IDynamicComponent[] = [];
  impactValuesFields: IDynamicComponent[] = [];
  impactFactorsFields: IDynamicComponent[] = [];
  IVList: RiskMethodologyImpactValue[];
  LVList: RiskMethodologyImpactValue[];
  IFList: RiskMethodologyImpactValue[];
  TVList: RiskMethodologyImpactValue[];
  testControl = new FormControl(null);
  public thresholdList: RiskMethodologyThresholdValue[] = [];
  public tempThresholdList: RiskMethodologyThresholdValue[] = [];
  // likelihoodValuesControl = new FormControl(null);
  // impactValuesControl = new FormControl(null);
  // impactFactorsControl = new FormControl(null);

  impactValuesList = [];

  public minThreshold;
  public maxThreshold;

  constructor(
    public viewModeService: ViewModeService,
    public appDialogService: AppDialogService,
    public impactFactorService: RiskMethodologyImpactFactorDataService,
    public impactValueService: RiskMethodologyImpactValueDataService,
    public likelihoodValueService: RiskMethodologyLikelihoodValueDataService,
    public thresholdValueService: RiskMethodologyThresholdValueDataService,
    private riskMethodologyDataService: RiskMethodologyDataService,
    public localeService: LocaleService
  ) {
    super(viewModeService, ModuleKeywords.RiskMethodology);
    this.initFormRepeaterFields();
  }

  ngOnInit(): void {
    // this.riskMethodologyDataService.fetchActiveRiskMethodology().subscribe({
    //   next: (res) => {
    //     this.setData(this.activeMethodology);
    //   },
    //   error: (error) => {

    //   },
    //   complete: () => {},
    // });
    this.acceptableRiskControl.valueChanges.subscribe(value => {
      this.acceptableRisk = value;
    })
    this.formGroup.valueChanges.subscribe((active) => {
      this.tempThresholdList = [];
      this.reloadValues({ ...active });
    });
  }

  tempThresholdListChanges(event) {
    this.tempThresholdList = event ?? [];
  }

  private reloadValues(active: RiskMethodology) {
    this.activeMethodology = active;
    // this.extraImpactFactorFilters = [{ property: 'code', operation: 'IN', value: this.activeMethodology?.impactFactorsDto ? this.activeMethodology?.impactFactorsDto?.map(x => x.code) : [] },];
    this.extraThresholdFilters = [{ property: 'code', operation: 'IN', value: this.activeMethodology?.thresholdValuesDto ? this.activeMethodology?.thresholdValuesDto?.map(x => x.code) : [] },];
    this.thresholdList = this.activeMethodology?.thresholdValuesDto;
    this.calculateMinMax();
  }

  calculateMinMax() {
    this.maxThreshold =
      maxBy(this.activeMethodology?.impactValuesDto, 'value')?.value *
      maxBy(this.activeMethodology?.likelihoodValuesDto, 'value')?.value || 0;

    this.minThreshold =
      minBy(this.activeMethodology?.impactValuesDto, 'value')?.value *
      minBy(this.activeMethodology?.likelihoodValuesDto, 'value')?.value || 0;

    this.options = {
      floor: this.minThreshold,
      ceil: this.maxThreshold,
    };
    if (!this.acceptableRisk) {
      this.acceptableRiskControl.patchValue(this.maxThreshold);
    }
    if (this.acceptableRisk > this.maxThreshold) {
      this.acceptableRiskControl.patchValue(this.maxThreshold);
    }
    if (this.acceptableRisk < this.minThreshold) {
      this.acceptableRiskControl.patchValue(this.minThreshold);
    }

  }

  getData() {
    const retData = {
      // ...this.formGroup.getRawValue(),
      acceptableRisk: this.acceptableRisk,
      // thresholdValues: this.thresholdList ? [...(this.thresholdList.map(x => x.code))] : [],
    };

    // let currentTime = new Date().getTime();
    // let riskMeth: RiskMethodology = retData;

    return this.viewModeService.viewMode == 'create' ?
      this.getDataKeyValueFormat(retData)
      : this.getChangedFormValues(retData).updateItems;
  }

  setData(data: any) {
    let currentTimeStamp = new Date().getTime();

    this.IFList = data?.impactFactorsDto;
    this.IVList = data?.impactValuesDto;
    this.LVList = data?.likelihoodValuesDto;
    this.TVList = data?.thresholdValuesDto;
    if (this.timestamp != null) {
      data = {
        ...data,
        impactValuesDto: this.formGroup?.controls?.impactValuesDto?.value ?? data?.impactValuesDto,
        likelihoodValuesDto: this.formGroup?.controls?.likelihoodValuesDto?.value ?? data?.likelihoodValuesDto,
        thresholdValuesDto: this.formGroup?.controls?.thresholdValuesDto?.value ?? data?.thresholdValuesDto
      };
    }
    this.impactValuesList = data?.impactValuesDto;

    this.formGroup.patchValue({ ...data }, { emitEvent: false });

    this.data = data;
    this.reloadValues(data);
    this.acceptableRisk = this.activeMethodology?.acceptableRisk;
    this.acceptableRiskControl.patchValue(this.acceptableRisk ?? 0);
    if (this.data && this.timestamp == null) {
      this.timestamp = new Date().getTime();
    }
  }

  ngAfterViewInit(): void {
    this._setupSubscriptions();
    // this.setupFormSubscribers();
  }

  setupFormSubscribers() {
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      // name: new FormControl(null, Validators.required),
      // owner: new FormControl(null, Validators.required),
      // approver: new FormControl(null, Validators.required),
      // description: new FormControl(null),
      likelihoodValuesDto: new FormControl(null),
      impactValuesDto: new FormControl(null),
      impactFactorsDto: new FormControl([]),
      thresholdValuesDto: new FormControl(null),// ctrl => { return this.validateThreshold(ctrl, this.minThreshold, this.maxThreshold) }),
      acceptableRisk: new FormControl(null),
    });

    this.formGroup.controls.impactValuesDto.valueChanges.subscribe(x => {
      this.impactValuesList = x;
    })
  }

  initFormRepeaterFields() {

  }

  onSubmitConfirm(event: any) {
    let config: IConfirmationConfig = new IConfirmationConfig();

    config.position = IConfirmationPosition.top;

    config.header = this.localeService.translate(this.moduleString + '.' + `Are you sure about editing the current Risk Methodology`);
    config.message = this.localeService.translate(this.moduleString + '.' + 'This Action Will Reset The Current Registered Risks.');

    this.appDialogService.confirm(
      {
        accept: () => {
          this.onSubmitForm();
        },
      },
      config
    );
  }

  onThresholdChange() {
    this.formGroup.controls.thresholdValuesDto.patchValue(this.thresholdList);
  }

  onAddThreshold() {
    let threshold = <RiskMethodologyThresholdValue>{};
    threshold.color = '#999999';
    threshold.min = 1;
    threshold.max = 1;

    if (this.thresholdList == undefined || this.thresholdList.length == 0) {
      threshold.max = this.maxThreshold;
      this.thresholdList = [threshold];
    } else {
      this.thresholdList.push(threshold);
    }

    this.activeMethodology.thresholdValuesDto = this.thresholdList;
    this.formGroup.controls.thresholdValuesDto.patchValue(this.thresholdList);
  }

  onRemoveThresholdItem(item) {
    let index = findIndex(this.thresholdList, item);
    this.thresholdList.splice(index, 1);
    this.calculateMinMax();
    this.formGroup.controls.thresholdValuesDto.patchValue(this.thresholdList);
  }

  validateThreshold(control: AbstractControl, minThreshold = 0, maxThreshold = 0): ValidationErrors | null {
    const list = control.value ?? [];

    if (list.length) {
      let prevThreshold = null;
      const span = maxThreshold - minThreshold;
      let sum = 0;
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        sum += element?.max - element?.min + (i != 0 ? 1 : 0);
      }
      if (sum != span) {
        return {
          thresholdError: "Thresholds contain gaps or are overlapped the range of threshold should span over from " + minThreshold + " to " + maxThreshold
        }
      }
      for (let i = 0; i < list.length; i++) {
        const element = list[i];
        if (prevThreshold && (prevThreshold.max + 1) !== element.min) {
          this.thresholdValid = false;
          return {
            thresholdError: "Thresholds contain gaps or are overlapped the range of threshold should span over from " + minThreshold + " to " + maxThreshold
          }
        }
        prevThreshold = element;
      }
    }
    this.thresholdValid = true;
    return null;
  }

  onCopyFromAnotherMethodology() {
    this.appDialogService.showDialog(
      BaseFormPopupComponent,
      this.localeService.translate(this.moduleString + '.' + 'Copy From Another Methodology'),
      (data) => {
        if (data) {
          this.createMethodologyItems(data?.methodology);
        }
      },
      {
        data: {
          dynamicViewComponent: RiskMethodologyCopyFormComponent,
          dataService: this.riskMethodologyDataService,
          filters: [],
          selectedRows: [],
          patchData: false,
          options: { itemId: this.data?.code },
          formData: {},
        },
      }
    );
  }

  createMethodologyItems(meth) {
    this.riskMethodologyDataService.getByIdOrCode(meth).subscribe(res => {
      if (res) {
        const lvList = res?.data?.likelihoodValuesDto;
        const ivList = res?.data?.impactValuesDto;
        const tvList = res?.data?.thresholdValuesDto;
        this.recursiveLvCreation(lvList, 0);
        this.recursiveIvCreation(ivList, 0);
        this.recursiveTvCreation(tvList, 0);
      }
    });
  }

  recursiveLvCreation(list, index) {
    if (list?.length <= index) {
      return;
    }

    const data = {
      name: list?.[index]?.name,
      value: list?.[index]?.value,
      occuresFrom: list?.[index]?.occuresFrom,
      occuresTo: list?.[index]?.occuresTo,
      occurrenceDuration: list?.[index]?.occurrenceDuration,
      displayOrder: list?.[index]?.displayOrder,
      methodology: this.data?.code
    };
    this.likelihoodValueService?.create(this.getDataKeyValueFormat(data)).subscribe(res => {
      if (res) {
        const newList = this.formGroup?.controls?.likelihoodValuesDto?.value ?? [];
        this.formGroup?.controls?.likelihoodValuesDto?.patchValue([...newList, res?.data].sort((a, b) => a?.displayOrder && b?.displayOrder ? (a.displayOrder - b.displayOrder) : -Infinity));
        this.recursiveLvCreation(list, index + 1);
      }
    })
  }

  recursiveIvCreation(list, index) {
    if (list?.length <= index) {
      return;
    }

    const data = {
      name: list?.[index]?.name,
      value: list?.[index]?.value,
      impactFactorOptions: list?.[index]?.impactFactorOptions,
      displayOrder: list?.[index]?.displayOrder,
      methodology: this.data?.code
    };
    this.impactValueService?.create(this.getDataKeyValueFormat(data)).subscribe(res => {
      if (res) {
        const newList = this.formGroup?.controls?.impactValuesDto?.value ?? [];
        this.formGroup?.controls?.impactValuesDto?.patchValue([...newList, res?.data].sort((a, b) => a?.displayOrder && b?.displayOrder ? (a.displayOrder - b.displayOrder) : -Infinity));
        this.recursiveIvCreation(list, index + 1);
      }
    })
  }

  recursiveTvCreation(list, index) {
    if (list?.length <= index) {
      return;
    }

    const data = {
      name: list?.[index]?.name,
      min: list?.[index]?.min,
      max: list?.[index]?.max,
      color: list?.[index]?.color,
      displayOrder: list?.[index]?.displayOrder,
      methodology: this.data?.code
    };
    this.thresholdValueService?.create(this.getDataKeyValueFormat(data)).subscribe(res => {
      if (res) {
        const newList = this.formGroup?.controls?.thresholdValuesDto?.value ?? [];
        this.formGroup?.controls?.thresholdValuesDto?.patchValue([...newList, res?.data].sort((a, b) => a?.displayOrder && b?.displayOrder ? (a.displayOrder - b.displayOrder) : -Infinity));
        this.recursiveTvCreation(list, index + 1);
      }
    })
  }

  get checkThresholdsAdd() {
    const thresholdList = this.tempThresholdList?.length ? this.tempThresholdList : this.thresholdList;
    return thresholdList?.length ?
      (thresholdList?.length > 1 ?
        thresholdList?.[0]?.min == this.minThreshold && thresholdList?.[thresholdList?.length - 1]?.max == this.maxThreshold :
        thresholdList?.[0]?.min == this.minThreshold && thresholdList?.[0]?.max == this.maxThreshold) :
      false
  }

  get statusBasedViewMode(): IViewMode {
    return !this.data || this.data?.status == 'DRAFT' ? this.fieldViewMode : 'view';
  }
}
