import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import { BasePage, ModuleKeywords } from '@shared/classes';
import { ViewModeService } from '@shared/services';

@Component({
  selector: 'app-risk-methodology-dashboard',
  templateUrl: './risk-methodology-dashboard.component.html',
  styleUrl: './risk-methodology-dashboard.component.scss'
})
export class RiskMethodologyDashboardComponent extends BasePage {
  modulesList: ModuleKeywords[] = ['RISK_METHODOLOGY', 'RISK_METHODOLOGY_LIKELIHOOD_VALUE', 'RISK_METHODOLOGY_IMPACT_VALUE', 'RISK_METHODOLOGY_IMPACT_FACTOR', 'RISK_METHODOLOGY_IMPACT_FACTOR_OPTION', 'RISK_METHODOLOGY_THRESHOLD_VALUE'];
  constructor(
    public viewModeService: ViewModeService,
    private localeService: LocaleService,
    private router: Router
  ) {
    super();
    this.SetPageValues({
      breadCrumb: {
        items: [{
          label: this.localeService.translate(
            `modules.risk.risk`
          ),
          routerLink: ['/risk']
        },
        {
          label: this.localeService.translate(
            `modules.risk.riskMethodology.riskMethodology`
          ),
        },
        {
          label: this.localeService.translate(
            `general.pages.dashboard`
          ),
        },]
      },
    });
  }

  ngOnInit(): void { }
}
