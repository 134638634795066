import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocaleService } from '@core/services/locale/locale.service';
import { BaseForm, FilterItem, ModuleKeywords, RiskMethodology, markFormGroupTouched } from '@shared/classes';
import { AppDialogService, IConfirmationConfig, IConfirmationPosition, ToastService, ViewModeService } from '@shared/services';
import { AppInjector } from 'app/app-injector';

@Component({
  selector: 'app-risk-methodology-copy-form',
  templateUrl: './risk-methodology-copy-form.component.html',
  styleUrl: './risk-methodology-copy-form.component.scss'
})
export class RiskMethodologyCopyFormComponent extends BaseForm<RiskMethodology> implements OnInit {
  extraFilters: FilterItem[] = [];
  constructor(public viewModeService: ViewModeService, public localeService: LocaleService, public appDialogService: AppDialogService) {
    super(viewModeService, ModuleKeywords.RiskMethodology);
  }

  ngOnInit(): void {
    this.extraFilters = [{ property: 'code', operation: 'NOT_EQUAL', value: this.popupOptions?.itemId }];
  }

  getData() {
    return this.formGroup?.getRawValue();
  }

  onSubmitFormSave() {
    let config: IConfirmationConfig = new IConfirmationConfig();

    config.position = IConfirmationPosition.top;

    config.header = this.localeService.translate(this.moduleString + '.' + `Are you sure about copying from another Methodology?`);
    config.message = this.localeService.translate(this.moduleString + '.' + 'This Action will not be available after applying it the first time.');

    this.appDialogService.confirm(
      {
        accept: () => {
          if (this.formGroup.valid) {
            this.formSubmitSave.emit(this.getData());
          } else {
            markFormGroupTouched(this.formGroup);
            const toastService = AppInjector.get(ToastService);
            toastService.warn('Validation Error', 'Please Fill Form Correctly');
          }
        },
      },
      config
    );
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      methodology: new FormControl(null, Validators.required),
    });
  }
}
