<app-base-page-item
  [data]="data"
  [recordStatusActions]="recordStatusActions"
  [extendLockTimeAction]="extendLockTimeAction"
  [showLocked]="showLocked"
  [showCommentsButton]="editMode && viewModeService.viewMode != 'create'"
  [showStateMachineAuditTrail]="showStateMachineAuditTrail"
  [showHistoryButton]="editMode && viewModeService.viewMode != 'create'"
  [showAuditButton]="editMode && viewModeService.viewMode != 'create'"
  [showAddCommentButton]="editMode && viewModeService.viewMode != 'create'"
  [showShareButton]="editMode && viewModeService.viewMode != 'create'"
  [showRecordStatus]="editMode && viewModeService.viewMode != 'create'"
  [breadCrumb]="breadCrumb"
  [sourceCode]="itemId"
  [hasEditChanges]="hasEditChanges$ | async"
  [fromType]="fromType"
  [showRelations]="editMode && viewModeService.viewMode != 'create'"
  [showViewSwitch]="editMode && viewModeService.viewMode != 'create'"
  (onViewModeChange)="onViewModeChange($event)"
  [moduleKeyword]="moduleKeyword"
>
  <ng-container page-header>
    <div class="flex flex-wrap gap-3">
      <h4 class="mb-0">
        {{ 'general.actions.' + (editMode ? viewModeService.viewMode : 'add') | translate }}
        {{ 'modules.risk.riskMethodology.riskMethodology' | translate }}
      </h4>
      @if (editMode) {
        @if (data?.code && isAllowedToEdit) {
          <div class="align-self-center">
            <app-badge-item
              [displayText]="true"
              [status]="data?.active ? 'gold' : 'red'"
              [text]="data?.active ? 'Active' : 'Inactive'"
              (click)="makeAsActive($event)"
              [leftSide]="false"
              [customIcon]="!data?.active ? 'pi pi-angle-down' : ''"
              [class.cursor-pointer]="!data?.active"
            ></app-badge-item>
          </div>
        }
        <div class="align-self-center">
          <app-badge-item
            [status]="data?.status"
            [text]="data?.status"
            (click)="showStateMachine()"
            class="cursor-pointer"
          ></app-badge-item>
        </div>
        <!-- <ng-container *ngxPermissionsOnly="organizationDeletePermission"> -->

        <!-- </ng-container> -->
      }
    </div>
  </ng-container>
  <ng-container page-actions>
    @if (viewMode == 'edit' && showRuleHandler) {
      <app-flat-rule-handler
        [actionMap]="actionsMap"
        [config]="{ requestService: requestService, itemId: itemId }"
      ></app-flat-rule-handler>
    }
  </ng-container>
  <app-risk-methodology-form
    (formChanges)="formChanges($event)"
    (formSubmit)="onSubmit($event)"
    (formSubmitSave)="onSubmit($event, 'PAGE')"
    (formSubmitInPlace)="onSubmit($event, 'NONE')"
    (cancelEvent)="onCancel($event)"
    [resetForm]="resetFormTrigger"
    (valueEditChanged)="valueEditChanged($event)"
    [formData]="data"
    [acceptableRiskControl]="acceptableRiskControl"
  ></app-risk-methodology-form>
  <ng-container *ngIf="editMode" below-main-card>
    <app-base-page [showViewSwitch]="editMode">
      <app-risk-methodology-matrix
        [acceptableRiskControl]="acceptableRiskControl"
        (formChanges)="formChanges($event)"
        (formSubmit)="onSubmit($event)"
        (formSubmitSave)="onSubmit($event, 'PAGE')"
        (formSubmitInPlace)="onSubmit($event, 'NONE')"
        (cancelEvent)="onCancel($event)"
        [resetForm]="resetFormTrigger"
        (valueEditChanged)="valueEditChanged($event)"
        [formData]="data"
      ></app-risk-methodology-matrix>
    </app-base-page>
  </ng-container>
</app-base-page-item>
