<app-base-page [breadCrumb]="breadCrumb" [cardContainer]="false">
  <!-- <ng-container page-header>
  <h4>Entities</h4>
</ng-container> -->
  <div class="grid">
    @for (moduleKeyword of modulesList; track moduleKeyword) {
      <app-module-card-view
        class="col-12 lg:col-4 p-3 hide-if-empty"
        [moduleKeyword]="moduleKeyword"
      ></app-module-card-view>
    }
  </div>
</app-base-page>
