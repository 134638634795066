import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@shared/shared.module';
import { RiskMethodologyComponent } from './component/RiskMethodology/RiskMethodology.component';
import { ImpactFactorOptionsFormPopupComponent } from './component/risk-methodology-form/impact-factor-options-form-popup/impact-factor-options-form-popup.component';
import { RiskMethodologyFormComponent } from './component/risk-methodology-form/risk-methodology-form.component';
import { RiskMethodologyItemComponent } from './component/risk-methodology-item/risk-methodology-item.component';
import { SummaryPopupComponent } from './component/summary-popup/summary-popup.component';
import { SummaryComponent } from './component/summary/summary.component';
import { ThresholdItemComponent } from './component/threshold-item/threshold-item.component';
import { RiskMethodologyListComponent } from './risk-methodology-list/risk-methodology-list.component';
import { RiskMethodologyRoutingModule } from './risk-methodology-routing.module';
import { RiskMethodologyMatrixComponent } from './component/risk-methodology-form/risk-methodology-matrix/risk-methodology-matrix.component';
import { RiskMethodologyCopyFormComponent } from './component/risk-methodology-form/risk-methodology-copy-form/risk-methodology-copy-form.component';
import { RiskMethodologyDashboardComponent } from './risk-methodology-dashboard/risk-methodology-dashboard.component';

@NgModule({
  declarations: [
    SummaryComponent,
    RiskMethodologyComponent,
    RiskMethodologyItemComponent,
    RiskMethodologyFormComponent,
    SummaryPopupComponent,
    ThresholdItemComponent,
    ImpactFactorOptionsFormPopupComponent,
    RiskMethodologyListComponent,
    RiskMethodologyMatrixComponent,
    RiskMethodologyCopyFormComponent,
    RiskMethodologyDashboardComponent,
  ],
  imports: [CommonModule, RiskMethodologyRoutingModule, SharedModule],
  //   entryComponents: [
  //     SummaryPopupComponent
  //   ],
  exports: [RiskMethodologyComponent],
})
export class RiskMethodologyModule {}
