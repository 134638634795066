import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocaleService } from '@core/services/locale/locale.service';
import {
  BaseTablePage,
  ButtonColor,
  MessageKeys,
  ModuleKeywordRootPath,
  ModuleKeywords,
  ModuleRoutePrefix,
  PermissionActions,
  RiskMethodologyDto,
  makePlural,
  routeToLocaleCase,
  toCamelCase,
  toKebabCase,
} from '@shared/classes';
import { AppDialogService, ExportDataService, ToastService } from '@shared/services';
import { RiskMethodologyMappingService } from '@shared/services/mappings/risk/risk-methodology-mapping.service';
import { AppInjector } from 'app/app-injector';
import { RiskMethodologyDataService } from '../../services/data/risk-methodology-data.service';

@Component({
  selector: 'app-risk-methodology-list',
  templateUrl: './risk-methodology-list.component.html',
  styleUrl: './risk-methodology-list.component.scss',
})
export class RiskMethodologyListComponent extends BaseTablePage<RiskMethodologyDto> implements OnInit {
  constructor(
    private requestService: RiskMethodologyDataService,
    exportService: ExportDataService,
    appDialogService: AppDialogService,
    router: Router,
    public toastService: ToastService,
    public localeService: LocaleService
  ) {
    super(
      requestService,
      exportService,
      appDialogService,
      router,
      {
        moduleKeyword: ModuleKeywords.RiskMethodology,
        routePrefix: ModuleRoutePrefix.Risk,
      },
      localeService
    );

    this.SetPageValues({
      breadCrumb: {
        items: [
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(ModuleKeywordRootPath[this.moduleKeyword])}`
            ),
            routerLink: [`${this.routePrefix ? this.routePrefix : toKebabCase(this.moduleKeyword)}`],
          },
          {
            label: this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${makePlural(toCamelCase(this.moduleKeyword))}`
            ),
            routerLink: [`/${this.routePrefix}${toKebabCase(this.moduleKeyword)}/list`],
          },
        ],
      },
      pageActions: [
        {
          label:
            this.localeService.translate('general.actions.add') +
            ' ' +
            this.localeService.translate(
              `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.${toCamelCase(this.moduleKeyword)}`
            ),
          id: 1,
          color: ButtonColor.Primary,
          icon: 'pi pi-plus',
          iconPos: 'left',
          command: this.addNewItem.bind(this),
          permission: `${PermissionActions.Create}${this.moduleKeyword}`,
        },
      ],
    });
  }

  ngOnInit(): void { }
  setTableActions() {
    this.tableActions = [
      {
        id: 2,
        icon: 'pi pi-check',
        color: ButtonColor.Secondary,
        command: this.makeActive.bind(this),
        displayCommand: (data: RiskMethodologyDto) =>
          data?.recordStatus &&
          data?.recordStatus == 'ACTIVE' && !data?.active && data?.status == 'PUBLISHED',
        permission: `${PermissionActions.Delete}${ModuleKeywords.RiskMethodology}`,
        tooltipText: 'Make Active',
      },
    ];
  }

  makeActive(row: RiskMethodologyDto) {
    if (row && !row?.active) {
      this.appDialogService.confirmPopup(
        {
          accept: () => {
            this.requestService.active(row?.code).subscribe({
              next: (res) => {
                this.toastService.success(MessageKeys.success, MessageKeys['update' + this.moduleKeyword]);
                this.loadDataWrapper();
              },
              error: (err) => { },
            });
          },
        },
        event?.target,
        {
          message: this.localeService.translate(
            'modules.entity.riskMethodology.This will mark the current Risk Methodology as Active and reset others, proceed?',
            'This will mark the current Risk Methodology as Active and reset others, proceed?'
          ),
          header: this.localeService.translate(
            'modules.entity.riskMethodology.Change Active Active',
            'Change Active Methodology'
          ),
          icon: 'pi pi-exclamation-triangle',
        }
      );
    }

  }

  setCols() {
    const mappingService = AppInjector.get(RiskMethodologyMappingService);
    this.cols = [...mappingService.tableFields].map((x) => {
      return {
        ...x,
        name: this.localeService.translate(
          `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.fields.${x.key}.label`,
          x.name
        ),
        translationKeyPrefix:
          x.translationKeyPrefix ||
          `modules.${routeToLocaleCase(ModuleKeywordRootPath[this.moduleKeyword])}.${toCamelCase(this.moduleKeyword)}.fields.${x.key}`,
      };
    });
  }
}
