import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/auth/guards/auth.guard';
import { AppPermissions } from '@shared/classes';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { RiskMethodologyComponent } from './component/RiskMethodology/RiskMethodology.component';
import { RiskMethodologyItemComponent } from './component/risk-methodology-item/risk-methodology-item.component';
import { RiskMethodologyDashboardComponent } from './risk-methodology-dashboard/risk-methodology-dashboard.component';
import { RiskMethodologyListComponent } from './risk-methodology-list/risk-methodology-list.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: RiskMethodologyDashboardComponent,
  }, {
    path: 'list',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyListComponent,
  },
  {
    path: 'active',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyComponent,
  },
  {
    path: 'create',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyItemComponent,
  },
  {
    path: 'create/clone/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyItemComponent,
  },
  {
    path: 'view/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.ReadRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyItemComponent,
  },
  {
    path: 'edit/:id',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.UpdateRiskMethodology],
        redirectTo: '/access',
      },
    },
    component: RiskMethodologyItemComponent,
  },
  {
    path: 'settings',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteRiskMethodology],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'bulk-operation',
    canActivate: [NgxPermissionsGuard],
    data: {
      permissions: {
        only: [AppPermissions.DeleteRiskMethodology],
        redirectTo: '/access',
      },
    },
    loadChildren: () => import('./bulk-operations/bulk-operations.module').then((m) => m.BulkOperationsModule),
  },
  {
    path: 'risk-methodology-impact-factor',
    loadChildren: () => import('./risk-methodology-impact-factor/risk-methodology-impact-factor.module').then((m) => m.RiskMethodologyImpactFactorModule),
  },
  {
    path: 'risk-methodology-impact-factor-option',
    loadChildren: () => import('./risk-methodology-impact-factor-option/risk-methodology-impact-factor-option.module').then((m) => m.RiskMethodologyImpactFactorOptionModule),
  },
  {
    path: 'risk-methodology-impact-value',
    loadChildren: () => import('./risk-methodology-impact-value/risk-methodology-impact-value.module').then((m) => m.RiskMethodologyImpactValueModule),
  },
  {
    path: 'risk-methodology-likelihood-value',
    loadChildren: () => import('./risk-methodology-likelihood-value/risk-methodology-likelihood-value.module').then((m) => m.RiskMethodologyLikelihoodValueModule),
  },
  {
    path: 'risk-methodology-threshold-value',
    loadChildren: () => import('./risk-methodology-threshold-value/risk-methodology-threshold-value.module').then((m) => m.RiskMethodologyThresholdValueModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RiskMethodologyRoutingModule { }
